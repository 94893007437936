import * as yup from "yup";
import { NIGERIA_CODE } from "../../lib/constants";

export const onboardBusinessSchema = yup.object().shape({
  account_type: yup.string(),
  business_name: yup.string().required("Business name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!.%*?&])[A-Za-z\d@$!%*?&.]{8,}$/,
      "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character (.@$!%*?&)"
    ),
  phone: yup.string().required("Phone number is required"),
  discovery_method: yup.string().required("Discovery method is required")
});

export const onboardTalentSchema = yup.object().shape({
  account_type: yup.string(),
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!.%*?&])[A-Za-z\d@$!%*?&.]{8,}$/,
      "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character (.@$!%*?&)"
    ),
  phone: yup.string().required("Phone number is required"),
  discovery_method: yup.string().required("Discovery method is required")
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required")
});

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!.%*?&])[A-Za-z\d@$!%*?&.]{8,}$/,
      "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character (.@$!%*?&)"
    ),
  confirm_password: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match")
});

export const loginSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required")
});

export const businessComplianceSetupSchema = yup.object().shape({
  first_name: yup
    .string()
    .required("First name is required")
    .typeError("First name is required"),
  last_name: yup
    .string()
    .required("Last name is required")
    .typeError("Last name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phone: yup.string().required("Phone number is required"),
  id_type: yup.string().required("ID type is required"),
  identification: yup.mixed().required("ID is required"),
  incorporation_country: yup
    .string()
    .required("Incorporation country is required"),
  business_name: yup
    .string()
    .required("Business name is required")
    .typeError("Business name is required"),
  rc_number: yup.string().when(["incorporation_country"], {
    is: (incorporation_country: string) =>
      incorporation_country === NIGERIA_CODE,
    then: yup.string().required("RC number is required")
  }),
  kyc_status: yup.string().required("KYC status is required"),
  business_sector: yup.string().required("Business sector is required"),
  certificate: yup.mixed().required("Certificate is required")
});

export const talentComplianceSetupSchema = yup.object().shape({
  id_type: yup.string().required("ID type is required"),
  identification: yup.mixed().required("ID is required"),
  country_of_residence: yup
    .string()
    .required("Country of residence is required"),
  nationality: yup.string().required("Nationality is required")
});

export const teamSetupSchema = yup.object().shape({
  name: yup.string().required("Team name is required"),
  description: yup.string().required("Team description is required")
});

export const talentSetupSchema = yup.object().shape({
  last_name: yup.string().required("Last name is required"),
  first_name: yup.string().required("First name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phone: yup.string().nullable(),
  start_date: yup.string().required("Start date is required"),
  role: yup.string().required("Role is required"),
  team_id: yup.string().nullable(),
  employment_type: yup.string().required("Employment type is required"),
  currency: yup.string().required("Currency is required"),
  amount: yup
    .number()
    .min(0)
    .required("Amount is required")
    .typeError("Amount must be a number")
});

export const talentUploadSchema = yup.object().shape({
  file: yup.mixed().required("File is required")
});

export const newDocumentSchema = yup.object().shape({
  id_type: yup.string().required("Document type is required"),
  identification: yup.mixed().required("File is required")
});
